import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import useAlgoliaResponse from 'hooks/useAlgoliaResponse';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { Container } from 'layout';
import Layout from 'layout/Layout';
import AlgoliaFilters from 'components/algolia/AlgoliaFilters';
import Pagination from 'components/elements/Pagination';
import ArticlesList from 'components/sections/ArticlesList';
import { IArticleCardInList } from 'components/sections/ArticlesList/models';
import HeroBanner from 'components/sections/HeroBanner';
import calculateLimitInListing from 'utils/listing';

import { IPropsAlpPage } from './models';

import './AlpPage.scss';

const COMPONENT_ID = 'articleListFilter';

const AlpPage: FC<IPropsAlpPage> = ({
  data: { pageData, defaultArticles, alpSettings },
  pageContext,
}) => {
  const {
    seo,
    urls,
    createDate,
    updateDate,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
    filtersSection: filtersSectionPageData,
    pagination: paginationPageData,
    listingSettings: listingSettingsPageData,
    articleCardsSettings: articleCardsSettingsData,
    ctaCard: ctaCardData,
    heroBanner,
  } = pageData;

  const { breadcrumbs, lang, url, category } = pageContext;
  const listingSettings = listingSettingsPageData?.length
    ? listingSettingsPageData
    : alpSettings.nodes[0].listingSettings;
  const pagination = paginationPageData?.length
    ? paginationPageData
    : alpSettings.nodes[0].pagination;
  const filtersSection = filtersSectionPageData?.length
    ? filtersSectionPageData
    : alpSettings.nodes[0].filtersSection;
  const articleCardsSettings = articleCardsSettingsData?.length
    ? articleCardsSettingsData
    : alpSettings.nodes[0].articleCardsSettings;
  const ctaCard = ctaCardData?.length ? ctaCardData : alpSettings.nodes[0].ctaCard;

  const { isMobile, isInit } = useScreenRecognition();

  const articles = defaultArticles?.nodes;
  const isSmallDevice = isMobile;
  const limit = calculateLimitInListing(isSmallDevice, isInit, listingSettings);

  const {
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
    itemsTotal,
    handleActiveListPage,
    activeListPage,
    paginatedItems,
    pageCount,
  } = useAlgoliaResponse({ defaultItems: articles || [], limitOnPage: limit, isSortByOrder: true });

  const paginatedItemsSorted = useMemo(
    () =>
      paginatedItems?.map((item) => {
        const dataSource = articles?.find((defaultItem) => item.url === defaultItem.url)!;

        return {
          ...dataSource,
        };
      }) as IArticleCardInList[],
    [paginatedItems]
  );

  return (
    <Layout
      data-testid="AlpPage"
      seo={seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="alp-page"
    >
      <HeroBanner
        data={heroBanner}
        breadcrumbs={breadcrumbs}
        isShowBreadcrumbsMobile={showBreadcrumbsMobile}
        isShowBreadcrumbsDesktop={showBreadcrumbsDesktop}
      />

      <Container>
        <div id={COMPONENT_ID} className="alp-page__filters-wrapper">
          <AlgoliaFilters
            indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-articles`}
            filtersSection={filtersSection}
            saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
            handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
            handleHitsResponseActivated={handleHitsResponseActivated}
            handleActiveListPage={handleActiveListPage}
            isHitsResponseActivated={isHitsResponseActivated}
            lang={lang}
            isSmallDevice={isSmallDevice}
            itemsTotal={itemsTotal}
            itemsCurrent={paginatedItems?.length || 0}
            category={category}
          />
          <ArticlesList
            articles={paginatedItemsSorted}
            articleCardsSettings={articleCardsSettings}
            ctaCard={ctaCard}
          />

          {itemsTotal > limit ? (
            <Pagination
              data={pagination}
              handleActiveListPage={handleActiveListPage}
              pageCount={pageCount}
              active={activeListPage}
              scrollTargetId={COMPONENT_ID}
              isSmallDevice={isSmallDevice}
            />
          ) : null}
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String!, $category: Int) {
    pageData: alpPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentAlpPage
    }
    defaultArticles: allArticlePage(
      filter: { lang: { eq: $lang }, categories: { elemMatch: { id: { eq: $category } } } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    alpSettings: allAlpSettingsType(filter: { lang: { eq: $lang } }) {
      nodes {
        filtersSection {
          ...FragmentFiltersSection
        }
        pagination {
          ...FragmentPagination
        }
        listingSettings {
          ...FragmentCommonListingSettings
        }
        ctaCard {
          ...FragmentButton
        }
        articleCardsSettings {
          ...FragmentCommonArticleSettings
        }
      }
    }
  }
`;

export default AlpPage;
