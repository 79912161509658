import React, { FC } from 'react';

import ArticleCard from 'components/cards/ArticleCard';

import { IPropsArticlesList } from './models';

import './ArticlesList.scss';

const ArticlesList: FC<IPropsArticlesList> = ({ articles, articleCardsSettings, ctaCard }) => (
  <div data-testid="ArticlesList" className="articles-list" id="products_collection">
    {articles?.map((item) => (
      <ArticleCard
        key={item.url}
        data={item.previewData[0]}
        url={item.url}
        cta={ctaCard}
        cardSettings={articleCardsSettings}
      />
    ))}
  </div>
);

export default ArticlesList;
